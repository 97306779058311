div#bottom {
    position: relative;
    padding: 1px 5px;
    margin: 5px 0px 0px 0px;
}

div#top {
    position: relative;
    background-color: #c2dfef;
    /* padding: 5px; */
    margin: 0px 0px 5px 0px;
}

div#top_header {
    margin: 0px;
    padding: 0px;    
}